@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


.Container {
  background: rgba(255, 255, 255, 0.7);
  z-index: 999999999 !important;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    outline: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:nth-child(3) {
      padding: 10px;
    }

    &:first-child {
      background: none !important;
    }

    > h4 {
      font-family: 'Popins-SemiBold';
      font-size: 48px;
      line-height: 68px;
      text-align: center;
      @include Dott;
      @include break(550px) {
        font-size: 36px;
      }
    }

    > p {
      @include FontRegular(18, 29);
      color: rgba(0, 0, 0, 1);
      text-align: center;
      @include break(550px) {
        font-size: 14px;
        max-width: 350px;
      }

    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 176px;
      height: 48px;
      border-radius: 30px;
      background: rgba(0, 0, 0, 1);
      @include FontSemiBold(16, 24);
      color: #ffffff;
      margin-top: 32px;
      text-transform: uppercase;
      @include break(550px) {
        height: 40px;
        font-size: 16px;
        max-width: 156px;
      }
    }
  }
}
