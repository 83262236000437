@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


.Wrapper {
  background: rgba(11, 5, 2, 1);
  @include CommonWrapper;
  height: 100%;
  position: relative;
  overflow: hidden;
  @include break(900px) {
    padding-bottom: 20px;
  }
  @include break(500px) {
    overflow: hidden;
  }

  .Container {
    @include CommonContainer;
    padding: 150px 0 200px 0;
    position: relative;

    .DescBlock {
      position: relative;
      z-index: 99;

      .Title {
        @include FontSemiBold(48, 92);
        color: #ffffff;
        @include Dott;
        @include break(500px) {
          font-size: 47px;
          line-height: 66px;
          text-align: center;
        }
      }

      .Desc {
        @include FontRegular(18, 29);
        color: white;
        max-width: 560px;
        margin-top: 8px;
        @include break(500px) {
          text-align: center;
        }
      }

      .BtnsBlock {
        display: flex;
        gap: 24px;
        margin-top: 64px;
        @include break(500px) {
          display: none;
        }

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 170px;
          height: 48px;
          padding: 9px 32px 9px 32px;
          border-radius: 30px;
          @include FontSemiBold(14, 21);
          color: #ffffff;
        }

        .ProjectBtn {
          background: none;
          border: 1px solid #ffffff;
        }

        .ContactBtn {
          background: $accentColor;
        }
      }
    }

    .Circle {
      pointer-events: none;
      z-index: 12;
      min-width: 73%;
      height: 114%;
      position: absolute;
      bottom: -5%;
      right: -39%;
      max-height: 900px;

      @include break(1400px) {
        right: -41%;
      }
      @include break(1100px) {
        right: -46%;
      }
      @include break(1000px) {
        right: -50%;
      }
      @include break(700px) {
        height: 140%;
        left: 50%;
        top: 50%;
        right: 0;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }

    .CircleGif {
      min-width: 1180px;
      height: 111%;
      position: absolute;
      bottom: -5%;
      right: -32%;

      @include break(1140px) {
        right: -37%;
      }
      @include break(1040px) {
        right: -52%;
      }
      @include break(850px) {
        right: -55%;
      }
      @include break(780px) {
        right: -62%;
      }
      @include break(550px) {
        min-width: 1450px;
        height: 130%;
        left: 50%;
        transform: translate(-50%, 4%);
      }
      @include break(360px) {
        left: 50%;
        transform: translate(-50%, 5%);
        height: 121%;
      }
    }

    .WrSvgs {
      display: none;

      @include break(500px) {
        position: absolute;
        z-index: 99999;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
      }

      .Switcher {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

