.App {
    text-align: center;
    position: relative;
    background: rgba(11, 5, 2, 1);
    overflow: hidden;
}

.Container {
    position: relative;
    top: 95px;
    @media screen and (max-width: 540px) {
        top: 68px;
    }
}

.Wr {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 34.14%);
}